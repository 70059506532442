import React from "react";
const OrderList=(()=>{
    return(
        <ol>
            <li>Dark Night</li>
            <li>Stranger Things</li>
            <li>Lucky Key</li>
            <li>Mr. Rebot</li>
        </ol>
    )
});
export default OrderList;