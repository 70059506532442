// this is my bio data
const name= "Jalal Nasir";
function education(){
    let edu="Bs Computer Science";
    return edu;
   
}
const address={
    primaryadd:"Timergara Rabaat Dir Lower",
    secodaryadd:"Peshawar HayatAbad ",
    stree:"st 5"
}
export default name;
export {education, address};


