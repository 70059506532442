const Sum=((a=0,b=0)=>{
return(a+b)
});
const Mult=((a=1,b=1)=>{
    return(a*b)
    });
    const Div=((a=1,b=1)=>{
        return(a/b)
        });
        function Sub(a=0,b=0){
            return(a-b)
            };
 export default Sum;
 export {Mult,Div,Sub};