import React from "react";
import ReactDOM  from "react-dom";
import CardMap from './CardMap';
import Practice from './Practice';
import './index.css';
// import Greeting from './Componant/Greeting';


ReactDOM.render(
<>
<CardMap/>
<Practice/>
</>,
document.getElementById("root")
);